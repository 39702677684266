import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EmployeeModel } from 'src/app/project-insights/models/employee/employee.model';
import { EmployeeService } from 'src/app/project-insights/services/employee.service';
import { EmployeeUpdateModel } from 'src/app/project-insights/models/employee/employee-update.model';

@Component({
  selector: 'app-edit-employee-details-modal',
  templateUrl: 'edit-employee-details-modal.component.html',
  styleUrls: ['edit-employee-details-modal.component.scss']
})
export class EditEmployeeDetailsModalComponent extends BaseComponent implements OnInit {

  @Output() confirmed = new EventEmitter<boolean>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;
  modalRef?: BsModalRef;
  form = new UntypedFormGroup({});
  employee: EmployeeModel;
  message = new UntypedFormControl('', Validators.required);
  submitted = false;

  constructor(
    private fb: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    public toastr: ToastrService,
    private employeeService: EmployeeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initEmployeeUpdateForm();
  }

  initEmployeeUpdateForm()
  {
    this.form = this.fb.group({
      hourlyRate: [this.employee.hourlyRate?.toFixed(2)]
    });
  }
  
  onSubmit() {
    if (this.form.valid) {
      let employeeData: EmployeeUpdateModel =
      {
        id: this.employee.id,
        hourlyRate: this.form.get('hourlyRate').value
      }

      this.employeeService.updateEmployee(employeeData).subscribe(x => {
        this.toastr.success('Employee successfully updated!');
        this.confirmed.emit(true);
        this.bsModalRef.hide();
      })
    }
    else {
      this.toastr.error('This form has validation errors!', '');
    }
  }

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      return 'is-invalid'
    }
    return '';
  }

}
