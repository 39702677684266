import { Pipe } from '@angular/core';

@Pipe({ name: 'currencyCustomPipe' })
export class CurrencyCustomPipe {

    transform(value: string | number): string {
        if (typeof value === 'string') {
            value = +value;
        }
        const roundedValue = Math.round((value + Number.EPSILON) * 100) / 100;
        const formattedValue = roundedValue.toFixed(2);
        const newStr = formattedValue.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, "'");
        return newStr;
    }
}