<div class="container-fluid mt-3">
    <div class="container">
        <div id="stepperForm" class="bs-stepper">
            <div class="bs-stepper-header">
            <div class="step" data-target="#nameAndStatus">
                <button class="step-trigger">
                <span class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">Name & Status</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#invoiceInformation">
                <button class="step-trigger">
                <span class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">Invoice Information</span>
                </button>
            </div>
            <div class="line"></div>
            <div class="step" data-target="#emailSettings">
                <button class="step-trigger">
                <span class="bs-stepper-circle">4</span>
                <span class="bs-stepper-label">Email Settings</span>
                </button>
            </div>
            </div>
    
            <div class="bs-stepper-content">
    
            <!-- NAME AND STATUS -->
            <form [formGroup]="formNameAndStatus" class="col-4 offset-3">
                <div id="nameAndStatus" class="content fade">
                    <div class="row mb-2">
                        <div class="col-9">
                            <label for="billingGroupNameModal" class="form-label">{{'BillingGroupName' | transloco}}*</label>
                            <input id="billingGroupNameModal" type="text" class="form-control inlineInput" [ngClass]="validateField('billingGroupName', formNameAndStatus)" formControlName="billingGroupName">
                            <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}</div>
                        </div>
                        <div class="col-3">
                            <label for="isActiveModal" class="form-label">{{'Status' | transloco}}</label>
                            <div class="form-check form-switch">
                                <input id="isActiveModal" class="form-check-input" type="checkbox" role="switch" formControlName="isActive" [ngClass]="validateField('isActive', formNameAndStatus)" #isActiveSwitch>
                                <label for="isActiveModal" class="form-label">
                                    <span *ngIf="tempActiveState" class="badge bg-success">{{'Active' | transloco}}</span>
                                    <span *ngIf="!tempActiveState" class="badge bg-danger">{{'Inactive' | transloco}}</span>
                                </label>
                                <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}</div>
                            </div>
                        </div>
                    </div>
                <button class="btn btn-light border" routerLink="../" tooltip="Zurück zur Übersicht">{{'cancel' | transloco}}</button>
                <button (click)="submitNameAndStatus()" class="btn btn-primary float-end">{{'next' | transloco}}</button>
                </div>
            </form>
    
            <!-- INVOICE INFORMATION -->
            <form [formGroup]="formInvoiceInformation" class="col-6 offset-3">
                <div id="invoiceInformation" class="content fade">
                    <div class="row mb-2">
                        <div class="col-7">
                            <label for="customerOrderNumberModal" class="form-label">{{'CustomerOrderNumber' | transloco}}</label>
                            <input id="customerOrderNumberModal" type="text" class="form-control inlineInput"
                                [ngClass]="validateField('customerOrderNumber', formInvoiceInformation)" formControlName="customerOrderNumber">
                                <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
                        </div>
                        <!-- Language as typeahead-dropdown -->
                        <div class="col-5">
                            <label for="languageModal" class="form-label">{{ 'Language' | transloco }}*</label>
                            <ng-select id="languageModal" [virtualScroll]="true" placeholder="Select a Language.." formControlName="language" [ngClass]="validateField('language', formInvoiceInformation)">
                                <ng-option *ngFor="let language of languages" [value]="language">{{'languageEnum.' + language | transloco}}</ng-option>
                            </ng-select>
                            <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <!-- Bank as typeahead-dropdown -->
                        <div class="col-12">
                            <label for="bankModal" class="form-label">{{ 'BankAccount' | transloco }}*</label>
                            <ng-select id="bankModal" [virtualScroll]="true" placeholder="Select a Bank Account.." formControlName="bank" [ngClass]="validateField('bank', formInvoiceInformation)">
                                <ng-option *ngFor="let bank of banks" [value]="bank.id">{{bank.name}} - {{CurrencyEnum[bank.currency] | uppercase}}</ng-option>
                            </ng-select>
                            <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <label for="customerOrderNumberModal" class="form-label">{{'FolderName' | transloco}}</label>
                            <input id="customerOrderNumberModal" type="text" class="form-control inlineInput" formControlName="folderName">
                                <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
                                <small id="emailHelp" class="form-text text-muted">{{ 'ForDownload' | transloco }}</small>
                        </div>
                    </div>
                    <div class="col-12 mb-2">
                        <label for="bexioId" class="form-label">Bexio ID</label>
                        <input id="bexioId" type="number" [min]="0" class="form-control inlineInput"
                            [ngClass]="validateField('bexioId', formInvoiceInformation)" formControlName="bexioId">
                            <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
                    </div>

                    <button (click)="goBack()" class="btn btn-light border me-2">{{'goBack' | transloco}}</button>
                    <button (click)="cancel()" class="btn btn-light border"  tooltip="Zurück zur Übersicht">{{'cancel' | transloco}}</button>
                    <button (click)="submitInvoiceInformation()" class="btn btn-primary float-end">{{'next' | transloco}}</button>
                </div>
            </form>
    
            <!-- EMAIL SETTINGS -->
            <form [formGroup]="formEmailSettings" class="col-6 offset-3">
                <div id="emailSettings" class="content fade">
                    <div class="row mb-2">
                        <div class="col-12">
                            <label for="salutationModal" class="form-label">{{'Salutation' | transloco}}*</label>
                            <textarea id="salutationModal" type="text" class="form-control inlineInput" rows="3"
                                [ngClass]="validateField('salutation', formEmailSettings)" formControlName="salutation"></textarea>
                                <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <label for="recipientsModal" class="form-label">{{'Recipients' | transloco}}*</label>
                                </div>
                                <div class="card-body">
                                    <ng-container formArrayName="recipientsArray">
                                        <ng-container *ngFor="let recipientFormGroup of recipientsArray.controls; let i = index;">
                                            <div class="input-group mb-1" [formGroupName]="i">
                                                <span class="input-group-text" id="basic-addon1">{{i+1}}</span>
                                                <input formControlName="recipient" class="form-control" type="text" [ngClass]="{'is-invalid': !recipientFormGroup.get('recipient')?.valid && recipientFormGroup.get('recipient').touched}" placeholder="{{'email' | transloco}}">
                                                <div class="invalid-feedback" *ngIf="recipientFormGroup.get('recipient').getError('required') && recipientFormGroup.get('recipient').touched">{{ 'validationMsg.required' | transloco }}.</div>
                                                <div class="invalid-feedback" *ngIf="recipientFormGroup.get('recipient').getError('email') && recipientFormGroup.get('recipient').touched" >{{ 'validationMsg.emailNotValid' | transloco }}.</div>
                                                <button [hidden]="!recipientFormGroup.get('recipient')?.valid" type="button" class="btn btn-danger btn-sm" (click)="removeItem(i)"><i class="fa fa-trash"></i></button>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="card-footer">
                                    <button type="button" class="btn btn-primary btn-sm float-end" (click)="addItem()"><i class="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                <button (click)="goBack()" class="btn btn-light border me-2">{{'goBack' | transloco}}</button>
                <button (click)="cancel()" class="btn btn-light border"  tooltip="Zurück zur Übersicht">{{'cancel' | transloco}}</button>
                <button (click)="submitEverything()" class="btn btn-success float-end"><i class="fas fa-save"></i> {{'save' | transloco}}</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</div>
