import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { InvoiceByWorktimeModel } from "../../models/invoice/invoice-by-worktime.model";
import { InvoicesByWorktimeModel } from "../../models/invoice/invoices-by-worktime.model";
import { InvoicePositionUpdate } from "../../models/invoice/invoice-position-update.model";
import { InvoiceBasics } from "../../models/invoice/invoice-basics.model";
import { InvoicePosition } from "../../models/invoice/invoice-position.model";
import { Bank } from "../../models/invoice/bank.model";
import { InvoiceModel } from "../../models/invoice/invoice.model";
import { CurrencyEnum } from "src/app/shared/enums/currency.enum";
import { CreateBillingOverviewModel } from "../../models/billing-summary/create-billing-overview.model";

@Injectable({
    providedIn: 'root'
})

export class InvoiceFilesService {

    constructor(private http: HttpClient) { }

    public getInvoices(): Observable<InvoiceModel[]> {
        return this.http.get<InvoiceModel[]>(`${environment.api}/invoice`);
    }

    public generateInvoiceBillingOverviewById(id: string, model: CreateBillingOverviewModel): Observable<string> {
        return this.http.post<string>(`${environment.api}/invoiceFile/${id}/pdf`, model);
    }
    
    public deleteInvoiceBexioFileById(id: string): Observable<string> {
        return this.http.delete<string>(`${environment.api}/invoiceFile/${id}/bexio`);
    }

    public getBillingOverviewPdf(id: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/invoiceFile/${id}/pdf`);
    }

    public deleteBillingOverviewPdfFileById(id: string): Observable<string> {
        return this.http.delete<string>(`${environment.api}/invoiceFile/${id}/billingOverview`);
    }

    public getMultiFilesZipped(invoiceIds: Array<string>): Observable<string>{
        return this.http.post<string>(`${environment.api}/invoiceFile/multifile`, invoiceIds);
    }
}
