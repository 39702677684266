<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">

        <div class="card mb-3 sticky-toolbar">
            <div class="card-header">
                <div class="form-group">
                    <div class="btn-group me-2">
                        <button type="button" class="btn btn-primary me-2" (click)="createInvoice()">
                            <i class="fas fa-plus"></i>
                            Invoice
                        </button>
                    </div>

                    <div class="btn-group" dropdown #dropdown="bs-dropdown" placement="bottom right">
                        <a id="button-animated" dropdownToggle type="button" class="dropdown-toggle btn btn-light border me-2"
                                aria-controls="dropdown-animated">
                                <i class="fa-solid fa-check-to-slot"></i> Selection<span class="caret ms-1"></span>
                        </a>
                        <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu"
                            role="menu" aria-labelledby="button-animated">
                            <li class="cursor-pointer" role="menuitem" (click)="setSelectedRows()"><a class="dropdown-item">Select last billing cycle </a></li>
                            <li class="cursor-pointer" role="menuitem" (click)="clearSelection()"><a class="dropdown-item">Clear selection </a></li>
                            <hr> 
                            <li class="cursor-pointer" role="menuitem" (click)="downloadZipFile()"><a class="dropdown-item"><i class="fas fa-download"></i> Download invoice documents </a></li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="d-flex pb-2">
            <h2 class="pe-3">Invoices</h2>
        </div>

        <ag-grid-angular 
            #agGrid
            class="ag-theme-alpine " 
            style="width: 100%; height: calc(100vh - 250px);"
            *ngIf="gridConfigReady" 
            [gridOptions]="gridOptions" 
            (gridReady)="onGridReady($event)"
            [rowData]="invoices" 
            [rowSelection]="'multiple'"
            (selectionChanged)="onSelectionChanged()"
            [defaultColDef]="defaultColDef"
            enableCellTextSelection="true"
            [tooltipShowDelay]="0"
            [enableBrowserTooltips]="true">
        </ag-grid-angular>
        <app-ag-grid-footer></app-ag-grid-footer>

    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>