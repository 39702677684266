import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { DeleteConfirmationModalComponent } from 'src/app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { BillingGroupUpdateNameAndStatusModalComponent } from './update-name-and-status-modal/update-name-and-status-modal.component';
import { BillingGroupModel } from 'src/app/project-insights/models/billing-group/billing-group.model';
import { BillingGroupService } from 'src/app/project-insights/services/billing-group.service';
import { BillingGroupNameStatusModel } from 'src/app/project-insights/models/billing-group/billing-group-name-status.model';

@Component({
  selector: 'app-billing-group-details',
  templateUrl: './billing-group-details.component.html',
  styleUrls: ['./billing-group-details.component.scss']
})
export class BillingGroupDetailsComponent implements OnInit {

  loading: boolean;
  billingGroupId: string;
  billingGroup: BillingGroupModel;

  constructor(
    public location: Location,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private billingGroupService: BillingGroupService) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.params.subscribe(params => {
      if (params['id']) { this.billingGroupId = params['id']; }
    });
    this.loadBillingGroup();
  }

  loadBillingGroup() {
    this.billingGroupService.getBillingGroupById(this.billingGroupId)
      .subscribe((x: BillingGroupModel) => {
        this.billingGroup = x;
        this.loading = false;
      });
  }

  onEditBillingGroupName() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        billingGroup: this.billingGroup
      },
    };
    const bsModalRef = this.modalService.show(BillingGroupUpdateNameAndStatusModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe((updatedNameAndStatus: BillingGroupNameStatusModel) => {
        this.billingGroupService.updateBillingGroupNameAndStatus(this.billingGroupId, updatedNameAndStatus)
          .subscribe(x => {
            this.billingGroup.name = updatedNameAndStatus.name
            this.billingGroup.isActive = updatedNameAndStatus.isActive
            this.toastr.success("Data successfully updated!")
          },
            err => {
              this.toastr.error(err.error, "An error occured!")
            })
      })
  }

  onDeleteBillingGroup() {
    const initialState: ModalOptions = {
      initialState: {
        body: `Do you really want to delete the Billing group - <b>${this.billingGroup?.name}</b> ?`,
        okayButton: 'OK',
        cancelButton: 'Cancel',
      }
    };
    const bsModalRef = this.modalService.show(DeleteConfirmationModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe(() => {
        this.billingGroupService.deleteBillingGroup(this.billingGroupId)
          .subscribe(
            res => {
              this.toastr.success('Billing group successfully removed!')
              this.router.navigateByUrl('/billing-groups');
            },
            err => { this.toastr.error(`An error occured during remove billing group. ${err.error}`) }
          );
      });
  }
}
