<div class="container-fluid mt-3" *ngIf="!loading">

    <!-- Toolbar -->
    <div class="card sticky-toolbar mb-4">
        <div class="card-header">
            <button class="btn btn-light border me-2" routerLink="/billing-groups" tooltip="{{ 'goBack' | transloco}}" delay="300" placement="right">
                <i class="fas fa-arrow-left"></i>
            </button>

            <button type="button" class="float-end btn btn-danger" (click)="onDeleteBillingGroup()">
                <i class="fas fa-trash"></i> Löschen
            </button>
        </div>
    </div>

    <!-- Title -->
    <header>
        <h1>Billing Group - </h1>
        <h1>{{billingGroup?.name}}</h1>
        <span class="badge" [ngClass]="billingGroup.isActive ? 'bg-success' : 'bg-danger'">{{ billingGroup.isActive ? ('Active' | transloco) : ('Inactive' | transloco) }}</span>
        <button class="btn btn-primary btn-sm ms-3" tooltip="{{ 'edit' | transloco}}" (click)="onEditBillingGroupName()"><i class="fas fa-edit"></i></button>
    </header>

    <!-- Content -->
    <div class="row">
        <div class="col-6">
            <app-invoice-information [billingGroup]="billingGroup"></app-invoice-information>
        </div>
        <div class="col-6">
            <app-email-settings [billingGroup]="billingGroup"></app-email-settings>
        </div>
    </div>
</div>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>