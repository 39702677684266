export class StringHelper {
  static camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  static isNullOrEmptyOrWhitespace(value: string | null | undefined): boolean {
    return !value || value.trim() === '';
  }
}
