<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">

        <div class="card mb-3 sticky-toolbar">
            <div class="card-header">
                <div class="form-group">
                    <button class="btn btn-light border me-2" type="button" [routerLink]="['/invoices']" tooltip="Go back" [delay]="300" placement="bottom">
                        <i *ngIf="!formSubmitting" class="fas fa-arrow-left" ></i>
                    </button>

                    <button *ngIf="this.invoiceIsEditable" class="btn btn-primary me-2" type="button" (click)="updateInvoice()">
                        <span *ngIf="formSubmitting" class="spinner-border spinner-border-sm" role="status"></span>
                        <i *ngIf="!formSubmitting" class="fas fa-edit"></i>
                        Edit Invoice
                    </button>

                    <button *ngIf="!this.invoiceIsEditable" class="btn btn-warning me-2" type="button" (click)="deleteBexioInvoiceFile()">
                        <i *ngIf="!formSubmitting" class="fas fa-edit"></i>
                        Change invoice
                    </button>

                    <button *ngIf="!this.isBillingOverviewPdfGenerated" class="btn btn-primary me-2" type="button" (click)="generatePreview()">
                        <i class="fa-solid fa-eye"></i>
                        Generate Preview
                    </button>

                    <button *ngIf="!this.isBillingOverviewPdfGenerated" class="btn btn-primary me-2" type="button" (click)="generateBillingOverview()">
                        <i class="fa-solid fa-file-circle-plus"></i>
                        Generate Abrechnungsübersicht
                    </button>

                    <button *ngIf="this.invoice.statusId === InvoiceStatusEnum.Open || this.invoice.statusId === InvoiceStatusEnum.PendingChanges" class="btn btn-primary me-2" type="button" (click)="sendToBexio()">
                        <i class="fa-solid fa-file-import"></i>
                        Send to Bexio
                    </button>

                    <button *ngIf="this.sendEmailButtonVisability" class="btn btn-primary me-2" type="button" (click)="sendInvoiceAsEmail()" >
                        <i class="fas fa-envelope"></i>
                        Send by E-mail
                    </button>

                    <button *ngIf="!this.invoiceIsEditable && this.isInvoiceBexioPdfGenerated" class="btn btn-light border me-2" type="button" (click)="getBexioInvoicePdf()">
                        <i class="fas fa-download"></i>
                         Download Bexio Invoice
                    </button>

                    <button *ngIf="this.isBillingOverviewPdfGenerated" class="btn btn-light border me-2" type="button" (click)="getBillingOverviewPdf()">
                        <i class="fas fa-download"></i>
                         Download Abrechnungsübersicht
                    </button>

                    <button *ngIf="this.isBillingOverviewPdfGenerated && this.invoice.statusId !== InvoiceStatusEnum.SentToClient" class="btn btn-outline-danger me-2" type="button" (click)="deleteBillingOverviewPdf()">
                        <i class="fas fa-trash"></i>
                        Delete Abrechnungsübersicht
                    </button>

                    <button *ngIf="this.isEmailSent" class="btn btn-primary me-2" type="button" disabled>
                        <i class="fas fa-envelope"></i>
                        {{sendEmailText}}
                    </button>

                    <button *ngIf="this.invoiceIsEditable" class="btn btn-danger me-2 float-end" type="button" (click)="deleteInvoice()">
                        <i *ngIf="!formSubmitting" class="fas fa-trash"></i>
                         Delete Invoice
                    </button>
                </div>
            </div>
        </div>

        <div class="d-flex pb-2 ">
            <h2 class="pe-3">Invoice Details</h2>
        </div>
    
        <div class="row">
            <h5>Billing Group</h5>
            <div class="col">
                <p class="pre-line">
                    <textarea class="form-control" rows="3" disabled>{{
                        this.invoice.billingGroup.name
                    }}
                    </textarea>
                </p>
            </div>

            <div class="col invoice-details">
                <div class="invoice-detail">
                    <label>Date: </label>
                    <input class="form-control inline-input" disabled placeholder="dd. MMMM yyyy"
                        [value]="invoice.invoiceDate | date: 'dd.MM.yyyy'">
                </div>
                <div class="invoice-detail">
                    <label>Payment term in days: </label>
                    <input type="number" class="form-control inline-input" disabled placeholder="days"
                        [value]="invoice.paymentTermInDays">
                </div>
                <div class="invoice-detail">
                    <label>Payment deadline: </label>
                    <p>{{invoice.paymentDeadline | date: 'dd. MMMM yyyy'}}</p>
                </div>
                <div class="invoice-detail">
                    <label>Invoice Nr.:</label>
                    <p>{{invoice.invoiceNr}}</p>
                </div>
                <div class="invoice-detail">
                    <label>Bank account</label>
                    <input type="text" class="form-control inline-input-big" disabled
                        [value]="invoice.bank.name + ' - ' + CurrencyEnum[invoice.bank.currency].toUpperCase()">
                </div>
            </div>
        </div>

        <div>
            <p>
                <b>Rechnung</b>
                |
                <input type="text" class="form-control inline-input" placeholder="Customer order number" disabled
                    [value]="invoice.customerOrderNumber">
                |
                {{invoice.startDate | date: 'dd. MMMM yyyy'}}
                -
                {{invoice.endDate | date: 'dd. MMMM yyyy'}}
            </p>
        </div>

        <div class="col-9">
            <table class="table">
                <thead>
                    <tr>
                        <th *ngIf="this.invoiceIsEditable"></th>
                        <th>Description</th>
                        <th>VAT</th>
                        <th>Amount</th>
                        <th>Price</th>
                        <th>Total Price</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody cdkDropList (cdkDropListDropped)="changedSortPosition($event)">
                    <tr *ngFor="let position of sortBy('sort'); let i = index" cdkDrag cdkDragLockAxis="y" [cdkDragDisabled]="!this.invoiceIsEditable">
                        <td *ngIf="this.invoiceIsEditable" class="col">
                            <i class="fas fa-bars" style="cursor:move" cdkDragHandle></i>
                        </td>
                        <td class="col-4">
                            <input type="text" class="form-control" placeholder="name" disabled
                                [value]="position.description">
                        </td>

                        <td class="col-sm-2 col-xl-1">
                            <input type="text" class="form-control" placeholder="vat" disabled
                                [value]="position.vatPct + ' %'">
                        </td>

                        <td class="col-2">
                            <input type="text" class="form-control" placeholder="amount" disabled
                                [value]="position.amount | currencyCustomPipe">
                        </td>

                        <td class="col-2">
                            <input type="text" class="form-control" placeholder="price" disabled
                                [value]="position.price | currencyCustomPipe">
                        </td>

                        <td class="col-2">{{position.amount * position.price | currencyCustomPipe }}</td>

                        <td class="col-2 align-right">
                            <div class="btn-group">
                                <button class="btn btn-primary btn-sm" type="button"
                                    (click)="updatePosition(position, i)" tooltip="Edit Position" [delay]="300" [disabled]="!this.invoiceIsEditable">
                                    <i class="fas fa-edit"></i>
                                </button>
                                <button type="button" class="btn btn-danger btn-sm"
                                    (click)="deletePosition(position.id, i)" tooltip="Delete Position" [delay]="300" [disabled]="!this.invoiceIsEditable">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6"></td>
                        <td>
                            <button type="button" class="btn btn-primary btn-sm float-end" (click)="createPosition()" tooltip="Create Position" [delay]="300" [disabled]="!this.invoiceIsEditable">
                                <i class="fas fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td class="border-bottom-none"></td>
                        <td colspan="3"></td>
                        <td class="align-right">
                            Total excl. VAT<br />
                            VAT</td>
                        <td>
                            {{invoiceTotalPrice | currencyCustomPipe }}<br />
                            {{invoiceTotalVat | currencyCustomPipe }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4"></td>
                        <td class="align-right"><strong>Total incl. VAT</strong></td>
                        <td><strong>{{invoiceTotalPriceVat | currencyCustomPipe }}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>