import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import { CreateOrUpdatePositionModalComponent } from "./createOrUpdate-position-modal/createOrUpdate-position-modal.component";
import { EditInvoiceModalComponent } from "./edit-invoice-modal/edit-invoice-modal.component";
import { DeleteConfirmationModalComponent } from "src/app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component";
import { LoadingModalComponent } from "src/app/shared/components/loading-modal/loading-modal.component";
import { ConfirmationModalComponent } from "src/app/shared/components/confirmation-modal/confirmation-modal.component";
import { BaseComponent } from "@premotec/ngx-essentials";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceModel } from "src/app/project-insights/models/invoice/invoice.model";
import { InvoicePosition } from "src/app/project-insights/models/invoice/invoice-position.model";
import { CurrencyEnum } from "src/app/shared/enums/currency.enum";
import { InvoiceService } from "src/app/project-insights/services/invoice/invoice.service";
import { InvoiceBasics } from "src/app/project-insights/models/invoice/invoice-basics.model";
import { InvoicePositionsSortUpdate } from "src/app/project-insights/models/invoice/invoice-position-sort-update.model";
import { BexioInvoiceService } from "src/app/project-insights/services/bexio/bexio-invoice.service";
import { BillingSummaryService } from "src/app/project-insights/services/billing-summary.service";
import { CreateBillingOverviewModel } from "src/app/project-insights/models/billing-summary/create-billing-overview.model";
import { InvoiceFilesService } from "src/app/project-insights/services/invoice/invoice-file.service";
import { EmailService } from "src/app/project-insights/services/email.service";
import { InvoiceStatusEnum } from "src/app/shared/enums/invoice-status.enum";
import { StringHelper } from "src/app/shared/helper/string-helper.class";

@Component({
    templateUrl: './invoice-details.component.html',
    styleUrls: ['./invoice-details.component.scss'],
    providers: [DatePipe],
})
export class InvoiceDetailsComponent extends BaseComponent implements OnInit {

    public invoice: InvoiceModel;
    public invoicePositions: InvoicePosition[] = [];
    public invoiceId: string;
    public loading: boolean = true;
    public invoiceDate: Date;
    public connectedWithWorktimes: boolean;
    public invoiceIsEditable: boolean;
    public activityJournalIsVisible: boolean;
    public sendEmailText: string;
    public isEmailSent: boolean;
    public sendEmailButtonVisability: boolean;
    public rcptsFromBillingGroup: string;
    public salutationFromBillingGroup: string;
    isInvoiceBexioPdfGenerated: boolean;
    isBillingOverviewPdfGenerated: boolean;

    billingAddress: string;
    formSubmitting: boolean = false;
    CurrencyEnum = CurrencyEnum;

    invoiceTotalPrice: number;
    invoiceTotalVat: number;
    invoiceTotalPriceVat: number;

    modal?: BsModalRef | null;
    InvoiceStatusEnum = InvoiceStatusEnum;

    constructor(
        private invoiceService: InvoiceService,
        private route: ActivatedRoute,
        private router: Router,
        private datePipe: DatePipe,
        private modalService: BsModalService,
        private toastr: ToastrService,
        private bexioInvoiceService: BexioInvoiceService,
        private billingSummaryService: BillingSummaryService,
        private invoiceFileService: InvoiceFilesService,
        private emailService: EmailService
    ) {
        super();

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.invoiceId = params['id'];
            }
        });
    }

    ngOnInit(): void {
        this.loading = true;
        this.loadInvoice();
    }

    loadInvoice() {
        this.invoiceService.getInvoiceById(this.invoiceId).subscribe(x => {
            this.invoice = x;
            this.invoice.customerOrderNumber = this.initCustomerOrderNumber();
            this.connectedWithWorktimes = this.invoice.connectedWithWorktimes;
            this.invoiceIsEditable = this.invoice.isEditable;
            this.isBillingOverviewPdfGenerated = !StringHelper.isNullOrEmptyOrWhitespace(this.invoice.billingOverviewPdfFileId);
            this.isInvoiceBexioPdfGenerated = !StringHelper.isNullOrEmptyOrWhitespace(this.invoice.invoiceBexioPdfFileId);
            if(this.invoice.emailSentDate != null)
            {
                this.isEmailSent = true;
                this.sendEmailText = "E-mail sent " + this.datePipe.transform(new Date(this.invoice.emailSentDate), 'dd.MM.yyyy HH:mm').toString();
            }
            else
            {
                this.isEmailSent = false;
            }
            this.sendEmailButtonVisability = !this.invoiceIsEditable && !this.isEmailSent && this.invoice.statusId === InvoiceStatusEnum.SentToBexio && this.invoice.billingOverviewPdfFileId !== null;
            this.activityJournalIsVisible = !(this.invoiceIsEditable) && this.connectedWithWorktimes;
            this.invoiceDate = new Date(this.invoice.invoiceDate);
            this.loading = false;
            this.invoicePositions = [];
            let isInvoicePositionsSorted = false;
            //check if position is already sorted
            if (this.invoice.positions.length > 1 && !(this.invoice.positions[0].sort === 0 && this.invoice.positions[1].sort === 0)) {
                isInvoicePositionsSorted = true
            }
            for (let i = 0; i < this.invoice.positions.length; i++) {
                if (!isInvoicePositionsSorted) {
                    this.invoice.positions[i].sort = i //assign a sort number position 
                }

                this.invoicePositions.push(this.invoice.positions[i]);
            }
            this.calculatePrices();
        });
    }

    updateInvoice() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            class:'modal-xl',
            keyboard: false,
            initialState: {
                invoice: this.invoice,
            },
        };
        const bsModalRef = this.modalService.show(EditInvoiceModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((invoiceUpdate: InvoiceBasics) => {
                this.invoiceService.updateInvoice(this.invoice.id, invoiceUpdate)
                    .subscribe((updatedInvoice) => {
                        this.loadInvoice();
                        this.toastr.success('Invoice successfully updated!');
                    })
            })
    }

    deleteInvoice() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                body: "Do you really want to delete?",
                okayButton: "OK",
                cancelButton: "Cancel",
            }
        };
        const bsModalRef = this.modalService.show(DeleteConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
                this.invoiceService.deleteInvoice(this.invoiceId)
                    .subscribe(x => { this.router.navigateByUrl('/invoices'); });
            }
            )
    }

    importInvoicePdf()
    {
        this.bexioInvoiceService.importInvoicePdfById(this.invoiceId)
        .subscribe(() => {
            this.toastr.success('Pdf is successfully imported!');
        },
        error => {
            this.toastr.error(error.error)
        })
    }

    deleteBexioInvoiceFile() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                body: "In order to make the Invoice editable the Bexio PDF file needs to be deleted. <br> Do you really want to continue?",
                okayButton: "OK",
                cancelButton: "Cancel",
            }
        };
        const bsModalRef = this.modalService.show(DeleteConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
                this.invoiceFileService.deleteInvoiceBexioFileById(this.invoiceId)
                    .subscribe(() => {
                        this.loadInvoice();
                        this.toastr.success('Bexio Invoice PDF successfully deleted!');
                    },
                    error => {
                        this.toastr.error(error.error)
                    }).add(()=>{
                        this.modalService.hide(this.modal?.id);
                        this.modal = null;
                    });
            }
            )
    }

    deleteBillingOverviewPdf(){
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                body: "Do you really want to delete Abrechnungsübersicht PDF file for this invoice?",
                okayButton: "Yes",
                cancelButton: "Cancel",
            }
        };
        const bsModalRef = this.modalService.show(DeleteConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
                this.invoiceFileService.deleteBillingOverviewPdfFileById(this.invoiceId)
                    .subscribe(() => {
                        this.loadInvoice();
                        this.toastr.success('Abrechnungsübersicht PDF successfully deleted!');
                    },
                    error => {
                        this.toastr.error(error.error)
                    }).add(()=>{
                        this.modalService.hide(this.modal?.id);
                        this.modal = null;
                    });
            }
            )
    }

    createPosition() {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                vatDefault: this.invoice.vatDefault,
                createOrUpdateMode: 'create'
            },
        };
        const bsModalRef = this.modalService.show(CreateOrUpdatePositionModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((invoicePositionData) => {
                this.invoiceService.createInvoicePosition(this.invoiceId, invoicePositionData)
                    .subscribe((createdInvoicePosition) => {
                        this.invoicePositions.push(createdInvoicePosition);
                        this.calculatePrices();
                        this.toastr.success('Position successfully created!');
                    })
            }
            )
    }

    updatePosition(position: InvoicePosition, index: number) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                invoicePosition: position,
                createOrUpdateMode: 'update'
            },
        };
        const bsModalRef = this.modalService.show(CreateOrUpdatePositionModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe((invoicePositionData) => {
                this.invoiceService.updateInvoicePosition(position.id, invoicePositionData)
                    .subscribe((updatedInvoicePosition) => {
                        this.invoicePositions.splice(index, 1, updatedInvoicePosition);
                        this.calculatePrices();
                        this.toastr.success('Position successfully updated!',);
                    })
            }
            )
    }

    deletePosition(positionId: string, index: number) {
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                title: "Delete position",
                body: "Are you sure?",
                okayButton: "OK",
                cancelButton: "Cancel",
            }
        };
        const bsModalRef = this.modalService.show(DeleteConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
            .subscribe(() => {
                this.invoiceService.deleteInvoicePosition(positionId)
                    .subscribe(() => {
                        this.invoicePositions.splice(index, 1);
                        this.calculatePrices();
                        this.toastr.success('Position successfully deleted!');
                    })
            }
            )
    }

    calculatePrices() {
        this.invoiceTotalPrice = 0;
        this.invoiceTotalVat = 0;
        this.invoiceTotalPriceVat = 0;

        this.invoicePositions.forEach(x => {
            this.invoiceTotalPrice += x.amount * x.price;
            this.invoiceTotalVat += (x.amount * x.price) / 100 * x.vatPct
        });
        this.invoiceTotalVat = parseFloat(this.invoiceTotalVat.toFixed(3));
        
        this.invoiceTotalPriceVat = this.invoiceTotalPrice + this.invoiceTotalVat
    }

    getEmailRecepirnts()
    {
        let rec = "";
        for (let i = 0; i < this.invoice.billingGroup.emailRecipients.length - 1; i++) {
            rec += this.invoice.billingGroup.emailRecipients[i] + ", ";
        }
        rec += this.invoice.billingGroup.emailRecipients[this.invoice.billingGroup.emailRecipients.length-1];
        this.rcptsFromBillingGroup = rec;
    }

    sendInvoiceAsEmail()
    {
        this.getEmailRecepirnts();
        this.salutationFromBillingGroup = this.invoice.billingGroup.emailSalutation;
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                title: "Send invoice as Email",
                body: "Are you sure you want to send this invoice via e-mail to <b>" + this.rcptsFromBillingGroup + "</b> with the following salutation <b>" + this.salutationFromBillingGroup + "</b>.",
                okayButton: "YES",
                cancelButton: "Cancel",
            }
        };

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
        .subscribe(() => {
            const config = {
                ignoreBackdropClick: true,
                keyboard: false,
                initialState: {
                  title: 'Sending invoice as email'
                },
              }
          
            this.modal = this.modalService.show(LoadingModalComponent, config);
    
            this.emailService.sendInvoicesAsEmail([this.invoiceId])
                .subscribe(() => {
                    this.loadInvoice();
                    this.toastr.success('Email successfully sent!');
                },
                error => {
                    this.toastr.error(error.error)
                }).add(()=>{
                    this.modalService.hide(this.modal?.id);
                    this.modal = null;
                })
        }
        )
    }

    getBillingOverviewPdf(){
        const config = {
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
              title: 'Downloading Abrechnungsübersicht PDF'
            },
        }
      
        this.modal = this.modalService.show(LoadingModalComponent, config);

        this.invoiceFileService.getBillingOverviewPdf(this.invoiceId).subscribe(fileBytes => {
            var fileName = `Billing Overview - ${this.invoice.billingGroup.name}.pdf`;
            const data = `data:application/pdf;base64,${fileBytes}`;
            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        },
        error => {
            this.toastr.error(error.error)
        }).add(()=>{
            this.modalService.hide(this.modal?.id);
            this.modal = null;
        })
    }

    getBexioInvoicePdf() {

        const config = {
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
              title: 'Downloading Bexio Invoice PDF'
            },
        }
      
        this.modal = this.modalService.show(LoadingModalComponent, config);

        this.invoiceService.getBexioInvoicePdf(this.invoiceId).subscribe(fileBytes => {
            var fileName = `${this.datePipe.transform(this.invoice.invoiceDate, "YYYYMM")} ${this.invoice.billingGroup.name} Invoice ${this.invoice.invoiceNr}.pdf`;
            const data = `data:application/pdf;base64,${fileBytes}`;
            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            this.toastr.success('Invoice PDF successfully created');
        },
        error => {
            this.toastr.error(error.error)
        }).add(()=>{
            this.modalService.hide(this.modal?.id);
            this.modal = null;
        })
    }

    sendToBexio(){
        if(this.invoice.statusId === InvoiceStatusEnum.Open){
            this.sendInvoiceToBexio();
        }
        if(this.invoice.statusId === InvoiceStatusEnum.PendingChanges){
            this.updateInvoiceInBexio();
        }
    }

    sendInvoiceToBexio(){
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                title: "Send invoice to Bexio",
                body: "Are you sure you want to send this invoice to Bexio?",
                okayButton: "YES",
                cancelButton: "Cancel",
            }
        };

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
        .subscribe(() => {
            const config = {
                ignoreBackdropClick: true,
                keyboard: false,
                initialState: {
                  title: 'Sending invoice to Bexio'
                },
              }
          
            this.modal = this.modalService.show(LoadingModalComponent, config);
    
            this.bexioInvoiceService.sendInvoiceToBexio(this.invoiceId)
            .subscribe((res) => {
                this.loadInvoice();
                this.toastr.success('Invoice is successfully synchronized with Bexio!');
            },
            error => {
                this.toastr.error(error.error)
            }).add(()=>{
                this.modalService.hide(this.modal?.id);
                this.modal = null;
            })
        })
    }

    updateInvoiceInBexio(){
        const initialState: ModalOptions = {
            backdrop: 'static',
            keyboard: false,
            initialState: {
                title: "Resend invoice to Bexio",
                body: "Are you sure you want to send the updated invoice to Bexio? This action will remove the previous version of invoice pdf.",
                okayButton: "YES",
                cancelButton: "Cancel",
            }
        };

        const bsModalRef = this.modalService.show(ConfirmationModalComponent, initialState);

        bsModalRef.content?.confirmed.pipe(take(1))
        .subscribe(() => {
            const config = {
                ignoreBackdropClick: true,
                keyboard: false,
                initialState: {
                  title: 'Resend invoice to Bexio'
                },
              }
          
            this.modal = this.modalService.show(LoadingModalComponent, config);
    
            this.bexioInvoiceService.updateInvoiceInBexio(this.invoiceId)
            .subscribe((res) => {
                this.loadInvoice();
                this.toastr.success('Invoice is successfully synchronized with Bexio!');
            },
            error => {
                this.toastr.error(error.error)
            }).add(()=>{
                this.modalService.hide(this.modal?.id);
                this.modal = null;
            })
        })
    }

    generatePreview(){
        const config = {
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
              title: 'Generate Billing Overview PDF Preview'
            },
        }
      
        let model: CreateBillingOverviewModel = {
            startDate: this.invoice.startDate, 
            endDate: this.invoice.endDate,
            billingGroupId: this.invoice.billingGroupId
        }

        if(this.invoice.positions.length > 0){
            this.modal = this.modalService.show(LoadingModalComponent, config);

            this.billingSummaryService.generateBillingOverviewPreviewPdf(model).subscribe(fileBytes => {
                var fileName = `Billing Overview - ${this.invoice.billingGroup.name}.pdf`;
                const data = `data:application/pdf;base64,${fileBytes}`;
                var link = document.createElement('a');
                link.href = data;
                link.download = fileName;
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                this.toastr.success('Billing Overview PDF successfully created');
            },
            error => {
                this.toastr.error(error.error)
            }).add(()=>{
                this.modal.hide();
            })
        } else {
            this.toastr.error('Invoice should have at least one position for generation of the Billing Overview PDF.');
        }
    }

    generateBillingOverview(){
        const config = {
            ignoreBackdropClick: true,
            keyboard: false,
            initialState: {
              title: 'Generate Billing Overview PDF'
            },
        }
      
        let model: CreateBillingOverviewModel = {
            startDate: this.invoice.startDate, 
            endDate: this.invoice.endDate,
            billingGroupId: this.invoice.billingGroupId
        }

        if(this.invoice.positions.length > 0){
            this.modal = this.modalService.show(LoadingModalComponent, config);

            this.invoiceFileService.generateInvoiceBillingOverviewById(this.invoice.id, model)
            .subscribe((res) => {
                this.loadInvoice();
                this.toastr.success('Billing Overview PDF successfully added to the invoice!');
            },
            error => {
                this.toastr.error(error.error)
            }).add(()=>{
                this.modalService.hide(this.modal?.id);
                this.modal = null;
            })
        } else {
            this.toastr.error('Invoice should have at least one position for generation of the Billing Overview PDF.');
        }
    }

    updatePaymentTermInDays(days: number) {
        this.invoice.paymentTermInDays = days;
        this.invoice.paymentDeadline = new Date(this.invoice.invoiceDate);
        this.invoice.paymentDeadline.setDate(this.invoice.paymentDeadline.getDate() + Number(this.invoice.paymentTermInDays));
    }

    //Drag & Drop Functions
    changedSortPosition(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.invoicePositions, event.previousIndex, event.currentIndex);
        this.invoicePositions[event.previousIndex].sort = event.currentIndex;
        if (event.previousIndex > event.currentIndex) {
            for (let i = event.currentIndex; i < this.invoicePositions.length; i++) {
                this.invoicePositions[i].sort = i;
            }
        }
        if (event.previousIndex < event.currentIndex) {
            for (let i = event.currentIndex; i >= 0; i--) {
                this.invoicePositions[i].sort = i;
            }
        }
        this.updateSortPositions();

    }

    updateSortPositions() {
        let invoicePositionsUpdate: InvoicePositionsSortUpdate[] = [];

        let invoicePositionsMap = new Object();
        this.invoicePositions.forEach((x: any) => {
            let temp: InvoicePositionsSortUpdate = {
                id: x.id,
                sort: x.sort
            }
            invoicePositionsUpdate.push(temp)
            invoicePositionsMap[x.id] = x.sort;
        })

        this.invoiceService.updateInvoicePositionsSort(this.invoice.id, invoicePositionsMap).subscribe((x) => {
            this.toastr.success('Positions sort successfully updated!'),
                (err) => {
                    this.toastr.error('Error: Positions sort was NOT updated!')
                }

        })
    }

    sortBy(prop: string) {
        return this.invoicePositions.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    }

    initCustomerOrderNumber() {
        if (this.invoice.customerOrderNumber)
            return this.invoice.customerOrderNumber;
        else return "";
    }
}
