import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { InvoiceByWorktimeModel } from "../../models/invoice/invoice-by-worktime.model";
import { InvoicesByWorktimeModel } from "../../models/invoice/invoices-by-worktime.model";
import { InvoicePositionUpdate } from "../../models/invoice/invoice-position-update.model";
import { InvoiceBasics } from "../../models/invoice/invoice-basics.model";
import { InvoicePosition } from "../../models/invoice/invoice-position.model";
import { Bank } from "../../models/invoice/bank.model";
import { InvoiceByDates } from "../../models/invoice/invoice-by-dates.model";
import { InvoiceModel } from "../../models/invoice/invoice.model";
import { CurrencyEnum } from "src/app/shared/enums/currency.enum";

@Injectable({
    providedIn: 'root'
})

export class InvoiceService {

    constructor(private http: HttpClient) { }

    public getInvoices(): Observable<InvoiceModel[]> {
        return this.http.get<InvoiceModel[]>(`${environment.api}/invoice`);
    }

    public getInvoiceById(id: string): Observable<InvoiceModel> {
        return this.http.get<InvoiceModel>(`${environment.api}/invoice/${id}`);
    }

    public getVat(): Observable<number> {
        return this.http.get<number>(`${environment.api}/invoice/vat`);
    }

    public getPaymentTermInDays(): Observable<number> {
        return this.http.get<number>(`${environment.api}/invoice/paymentTermInDays`);
    }

    public getBankByCurrency(currency: CurrencyEnum): Observable<Bank> {
        return this.http.get<Bank>(`${environment.api}/invoice/bank/${currency}`);
    }

    public createInvoiceFromWorktime(invoice: InvoiceByWorktimeModel): Observable<string> {
        return this.http.post<string>(`${environment.api}/invoice/byWorktime`, invoice);
    }

    public createInvoicesFromWorktime(invoices: InvoicesByWorktimeModel): Observable<InvoiceModel[]> {
        return this.http.post<InvoiceModel[]>(`${environment.api}/invoice/invoicesByWorktime`, invoices);
    }

    public createInvoicePosition(invoiceId: string, newInvoicePosition: InvoicePositionUpdate): Observable<InvoicePosition> {
        return this.http.post<InvoicePosition>(`${environment.api}/invoice/${invoiceId}/position`, newInvoicePosition);
    }

    public getBexioInvoicePdf(invoiceId: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/invoiceFile/${invoiceId}/bexioPdf`);
    }

    public updateInvoice(id: string, invoiceUpdate: InvoiceBasics) {
        return this.http.put<InvoiceModel>(`${environment.api}/invoice/${id}`, invoiceUpdate);
    }

    public updateInvoicePosition(id: string, invoicePosition: InvoicePositionUpdate) {
        return this.http.put<InvoicePosition>(`${environment.api}/invoicePosition/${id}`, invoicePosition);
    }

    public updateInvoicePositionsSort(id: string, invoicePositionsSort: Object) {
        return this.http.put<InvoicePosition>(`${environment.api}/invoicePosition/${id}/sort-positions`, invoicePositionsSort);
    }

    public deleteInvoice(id: string): Observable<InvoiceModel> {
        return this.http.delete<InvoiceModel>(`${environment.api}/invoice/${id}`);
    }

    public deleteInvoicePosition(id: string): Observable<InvoiceModel> {
        return this.http.delete<InvoiceModel>(`${environment.api}/invoicePosition/${id}`);
    }
}
