import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { EstProjectCostsOverviewComponent } from './project-insights/components/est-project-costs/overview/est-project-costs-overview.component';
import { MsalGuard } from '@azure/msal-angular';
import { AuthErrorComponent } from './security/components/auth-error/auth-error.component';
import { LogoutComponent } from './security/components/logout/logout.component';
import { ImportComponent } from './project-insights/components/import/import.component';
import { IssueOverviewComponent } from './project-insights/components/issue/overview/issue-overview.component';
import { ProjectGroupOverviewComponent } from './project-insights/components/project-group/overview/project-group-overview.component';
import { ProjectGroupCreateModalComponent } from './project-insights/components/project-group/project-group-create/project-group-create.component';
import { ProjectGroupDetailsComponent } from './project-insights/components/project-group/project-group-details/project-group-details.component';
import { environment } from 'src/environments/environment';
import { TemplateTitleStrategy } from './_core/template-title-strategy';
import { ProjectStatusOverviewComponent } from './project-insights/components/project-status/overview/project-status-overview.component';
import { PlanningComponent } from './project-insights/components/management/planning/planning.component';
import { ManagementAuthGuard } from './security/guards/management-auth.guard';
import { BillingSummaryComponent } from './project-insights/components/management/billing-summary/billing-summary.component';
import { EvaluationComponent } from './project-insights/components/management/evaluation/evaluation.component';
import { InvoiceOverviewComponent } from './project-insights/components/management/invoice/overview/invoice-overview.component';
import { InvoiceDetailsComponent } from './project-insights/components/management/invoice/invoice-detail/invoice-details.component';
import { BillingGroupOverviewComponent } from './project-insights/components/management/billing-group/billing-group-overview/billing-group-overview.component';
import { BillingGroupCreateComponent } from './project-insights/components/management/billing-group/billing-group-create/billing-group-create.component';
import { BillingGroupDetailsComponent } from './project-insights/components/management/billing-group/billing-group-details/billing-group-details.component';
import { EmployeeOverviewComponent } from './project-insights/components/management/employee/employee-overview.component';
import { ProjectOverviewComponent } from './project-insights/components/management/project/project-overview.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./project-insights/project-insights.module').then((m) => m.ProjectInsightsModule),
    canActivate: [MsalGuard],
  },
  { path: 'logout', component: LogoutComponent , title: 'Logout'},
  { path: 'unauthorized', component: AuthErrorComponent, title: 'Unauthorized' },
  { path: 'import', component: ImportComponent, title: 'Data Transfer • Import'},
  { path: 'issues', component: IssueOverviewComponent, title: 'Issue Statistics • Issues'},
  { path: 'project-group', component: ProjectGroupOverviewComponent, title: 'Basic Data • Project Groups'},
  { path: 'project-group/create', component: ProjectGroupCreateModalComponent, title: 'Basic Data • Project Groups' },
  { path: 'project-group/:id', component: ProjectGroupDetailsComponent, title: 'Basic Data • Project Groups' },
  { path: 'project-status', component: ProjectStatusOverviewComponent, title: 'Issue Statistics • Project Status'},
  
  //{path: 'est-project-costs', component: EstProjectCostsOverviewComponent},
  //{ path: 'est-project-costs/:id', component: EstProjectCostsDetailsComponent },

  {
    path: 'hangfire', component: ImportComponent,
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: environment.hangfire
    }, 
    title: 'Data Transfer • Import'
  },
  {
    path: 'planning', component: PlanningComponent,
    title: 'Management • Planning',
    canActivate: [ManagementAuthGuard]
  }, 
  {
    path: 'billingSummary', component: BillingSummaryComponent,
    title: 'Management • Summary',
    canActivate: [ManagementAuthGuard]
  }, 
  {
    path: 'evaluation', component: EvaluationComponent,
    title: 'Management • Evaluation',
    canActivate: [ManagementAuthGuard]
  },
  { path: 'invoices',  
    title: 'Management • Invoices',
    component: InvoiceOverviewComponent 
  },
  { path: 'invoices/create',  
    title: 'Management • Invoices',
    component: InvoiceOverviewComponent 
  },
  { path: 'invoices/:id', 
    title: 'Management • Invoice Details',
    component: InvoiceDetailsComponent 
  },
  { path: 'billing-groups', 
    title: 'Management • Billing Groups',
    component: BillingGroupOverviewComponent 
  },
  { path: 'billing-groups/create',
    title: 'Management • Create Billing Group',
    component: BillingGroupCreateComponent 
  },
  { path: 'billing-groups/:id', 
    title: 'Management • Billing Group Details',
    component: BillingGroupDetailsComponent 
  },
  { 
    path: 'employees', 
    title: 'Management • Employees',
    component: EmployeeOverviewComponent 
  },
  { 
    path: 'projects', 
    title: 'Management • Projects',
    component: ProjectOverviewComponent 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    },
    { provide: TitleStrategy, useClass: TemplateTitleStrategy },
  ],
})
export class AppRoutingModule { }
