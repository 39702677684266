import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectModel } from 'src/app/project-insights/models/project/project.model';
import { BillingGroupModel } from 'src/app/project-insights/models/billing-group/billing-group.model';
import { BillingGroupService } from 'src/app/project-insights/services/billing-group.service';
import { ProjectService } from 'src/app/project-insights/services/project.service';
import { ProjectUpdateModel } from 'src/app/project-insights/models/project/project-update.model';

@Component({
  selector: 'app-edit-project-details-modal',
  templateUrl: 'edit-project-details-modal.component.html',
  styleUrls: ['edit-project-details-modal.component.scss']
})
export class EditProjectDetailsModalComponent extends BaseComponent implements OnInit {

  @Output() confirmed = new EventEmitter<boolean>();
  @Output() declined = new EventEmitter<boolean>();

  @Input() onlyConfirm = false;
  @Input() withoutHeader = false;
  modalRef?: BsModalRef;
  form = new UntypedFormGroup({});
  project: ProjectModel;
  billingGroups: BillingGroupModel[];
  message = new UntypedFormControl('', Validators.required);
  submitted = false;

  constructor(
    private fb: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    public toastr: ToastrService,
    public billingGroup: BillingGroupService,
    private projectService: ProjectService,
  ) {
    super();
  }


  ngOnInit(): void {
    this.initFormGroup();
    this.loadBillingGroups();
  }

  loadBillingGroups() {
    this.billingGroup.getAllActiveBillingGroups().subscribe(x => {
      this.billingGroups = x;
    })
  }

  initFormGroup() {
    this.form = this.fb.group({
      id: [this.project?.id, Validators.required],
      isActive: [this.project.isActive],
      hourlyRate: [this.project?.hourlyRate],
      billingGroupId: [this.project.billingGroup?.id],
    });
  }
  
  onSubmit() {
    let projectData: ProjectUpdateModel = {... this.form.value}
    if (this.form.valid) {
      this.projectService.updateProject(projectData).subscribe(x=> {
        this.toastr.success('Project successfully updated!');
        this.confirmed.emit(true);
        this.bsModalRef.hide();
      })
    }
    else {
      this.toastr.error('This form has validation errors!', '');
    }
  }

  decline() {
    this.declined.emit(true);
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field).invalid && this.form.get(field).touched) {
      return 'is-invalid'
    }
    return '';
  }

}
