import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "@premotec/ngx-essentials";
import { EmployeeModel } from "src/app/project-insights/models/employee/employee.model";
import { EvaluationSearchDataModel } from "src/app/project-insights/models/evaluation/evaluation-searchdata.model";
import { EvaluationModel } from "src/app/project-insights/models/evaluation/evaluation.model";
import { EmployeeService } from "src/app/project-insights/services/employee.service";
import { EvaluationService } from "src/app/project-insights/services/evaluation.service";

@Component({
    selector: 'app-evaluation',
    templateUrl: './evaluation.component.html',
    styleUrls: ['./evaluation.component.scss']
  })
  export class EvaluationComponent extends BaseComponent implements OnInit {
  
    loading: boolean = false;
  
    employees: EmployeeModel[] = [];
    evaluation: EvaluationModel;

    constructor(
        private employeeService: EmployeeService, 
        private evaluationService: EvaluationService) {
        super();
    }
    
    ngOnInit(): void {
        this.loadData();
    }

    loadData(){
        this.employeeService.getEduEmployees().subscribe(res => {
            this.employees = res;
        });
    }

    loadEvaluationBySearch(evaluationSearchData: EvaluationSearchDataModel) {
      
        if(evaluationSearchData.employeeId == null || evaluationSearchData.startDate == null || evaluationSearchData.endDate == null){
            this.evaluation = null;
        }
    
        if(evaluationSearchData.employeeId != "" && evaluationSearchData.employeeId != null && evaluationSearchData.startDate != null && evaluationSearchData.endDate != null){
            this.loading = true;

            this.evaluationService.getEvaluationBySearch(evaluationSearchData).subscribe(res => {
                this.evaluation = res;
            })
            this.loading = false;
        }
    }
}