import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@premotec/ngx-essentials';
import { EmployeeModel } from 'src/app/project-insights/models/employee/employee.model';
import { PlanningActivity, PlanningActivityWorktime } from 'src/app/project-insights/models/planning/planning-activity-worktime.model';
import { PlanningProjectGroup } from 'src/app/project-insights/models/planning/planning-project-group.model';
import { PlanningSearchData } from 'src/app/project-insights/models/planning/planning-searchdata.model';
import { PlanningWorktime } from 'src/app/project-insights/models/planning/planning-worktime.model';
import { EmployeeService } from 'src/app/project-insights/services/employee.service';
import { PlanningService } from 'src/app/project-insights/services/planning.service';
import { ProjectGroupService } from 'src/app/project-insights/services/project-group.service';
import { PlanningProjectGroupType } from 'src/app/shared/enums/planning-project-group-type.enum';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent extends BaseComponent implements OnInit {

  loading: boolean;

  employees: EmployeeModel[] = [];
  projectGroups: PlanningProjectGroup[];
  projectGroupsInternal: PlanningProjectGroup[];
  projectGroupsManagement: PlanningProjectGroup[];
  projectGroupsOverhead: PlanningProjectGroup[];

  managementActivities: PlanningActivity[];
  internalActivities: PlanningActivity[];
  
  worktimes: PlanningWorktime[];
  activities: PlanningActivity[];
  totalFte: number = 0;
  managementWorktimes: PlanningWorktime[];

  constructor(
    private employeeService: EmployeeService,
    private projectGroupService: ProjectGroupService,
    private planningService: PlanningService
    ) {
      super();
    }

    ngOnInit(): void {
        this.loading = true;
        this.loadData();
    }

    loadData(){
      this.employeeService.getEduEmployees().subscribe(res => {
          this.employees = res;
          this.employees.forEach(employee => {
            this.totalFte += employee.fte;
          });
      });

      this.projectGroupService.getPlanningProjectGroups().subscribe(res => {
        this.projectGroupsInternal = res
        .filter(x => x.type === PlanningProjectGroupType.Internal)
          .sort((a, b) => a.name.localeCompare(b.name)); 

        this.projectGroups = res
        .filter(x => x.type === PlanningProjectGroupType.Billable)
          .sort((a, b) => a.name.localeCompare(b.name));

        this.projectGroupsManagement = res
        .filter(x => x.type === PlanningProjectGroupType.Management)
          .sort((a, b) => a.name.localeCompare(b.name)); 

        this.projectGroupsOverhead = res
          .filter(x => x.type === PlanningProjectGroupType.Overhead)
          .sort((a, b) => a.name.localeCompare(b.name));
      });
    }

    loadPlanningBySearch(planningSearchData: PlanningSearchData) {
      this.loading = true;
    
      let searchData: PlanningSearchData;
      
      if (searchData != null) {
        planningSearchData = searchData;
      }
  
      if(planningSearchData != null){
        this.planningService.getPlanningWorktimesBySearch(planningSearchData).subscribe(res => {
          this.worktimes = res.planningWorktimes;
          this.activities = res.planningActivities;

          this.managementActivities = res.planningActivities
            .filter(x => x.type === PlanningProjectGroupType.Management)
            .sort((a, b) => a.name.localeCompare(b.name));
          
          this.internalActivities = res.planningActivities
            .filter(x => x.type === PlanningProjectGroupType.Internal)
            .sort((a, b) => a.name.localeCompare(b.name));

          this.loading = false;
        });
      }
    }

    getWorktime(employee: string, project: string): { billableHours: number, nonBillableHours: number } | null {
      const worktime = this.worktimes.find(wt =>
        wt.employeeId === employee && wt.projectGroupId === project
      );

      if (worktime && !(worktime.billableHours === 0 && worktime.nonBillableHours === 0)) {
          return {
              billableHours: parseFloat(worktime.billableHours.toFixed(2)),
              nonBillableHours: parseFloat(worktime.nonBillableHours.toFixed(2))
          };
      } else {
          return null; 
      }
    }

    getActivityWorktime(employee: string, activity: PlanningActivity): { billableHours: number, nonBillableHours: number } | null {
      if (activity) {
          const worktime = activity.worktimes.find(wt => wt.employeeId === employee);
  
          if (worktime && !(worktime.billableHours === 0 && worktime.nonBillableHours === 0)) {
              return {
                  billableHours: parseFloat(worktime.billableHours.toFixed(2)),
                  nonBillableHours: parseFloat(worktime.nonBillableHours.toFixed(2))
              };
          }
      }
      return null;
    }

    getTotalWorktimeForProject(projectGroupId: string): { totalBillableHours: number, totalNonBillableHours: number } | null {
      const total = this.worktimes
          .filter(wt => wt.projectGroupId === projectGroupId)
          .reduce((acc, curr) => {
              const roundedBillable = parseFloat(curr.billableHours.toFixed(2));
              const roundedNonBillable = parseFloat(curr.nonBillableHours.toFixed(2));

              acc.totalBillableHours += roundedBillable;
              acc.totalNonBillableHours += roundedNonBillable;

              return acc;
          }, { totalBillableHours: 0, totalNonBillableHours: 0 });

      // Check if both totals are 0 and return null if true
      if (total.totalBillableHours === 0 && total.totalNonBillableHours === 0) {
          return null;
      }

      return {
          totalBillableHours: parseFloat(total.totalBillableHours.toFixed(2)),
          totalNonBillableHours: parseFloat(total.totalNonBillableHours.toFixed(2))
      };
    }

    getTotalWorktimeForActivity(activity: PlanningActivity): { totalBillableHours: number, totalNonBillableHours: number } {
      const total = activity.worktimes.reduce((acc, curr) => {
        const roundedBillable = parseFloat(curr.billableHours.toFixed(2));
        const roundedNonBillable = parseFloat(curr.nonBillableHours.toFixed(2));
    
        acc.totalBillableHours += roundedBillable;
        acc.totalNonBillableHours += roundedNonBillable;
    
        return acc;
      }, { totalBillableHours: 0, totalNonBillableHours: 0 });

      if (total.totalBillableHours === 0 && total.totalNonBillableHours === 0) {
        return null;
      }
    
      return {
        totalBillableHours: parseFloat(total.totalBillableHours.toFixed(2)),
        totalNonBillableHours: parseFloat(total.totalNonBillableHours.toFixed(2))
      };
    }

    calculateTotalWorktime(employeeId: string): { totalBillableHours: number, totalNonBillableHours: number } {
      const total = {
        totalBillableHours: 0,
        totalNonBillableHours: 0
      };
    
      this.projectGroups.concat(this.projectGroupsInternal, this.projectGroupsManagement).forEach(project => {
        const worktime = this.getWorktime(employeeId, project.id);
        if (worktime) {
          const roundedBillable = parseFloat(worktime.billableHours.toFixed(2));
          const roundedNonBillable = parseFloat(worktime.nonBillableHours.toFixed(2));
          
          total.totalBillableHours += roundedBillable;
          total.totalNonBillableHours += roundedNonBillable;
        }
      });
    
      this.internalActivities.concat(this.managementActivities).forEach(activity => {
        const worktime = this.getActivityWorktime(employeeId, activity);
        if (worktime) {
          const roundedBillable = parseFloat(worktime.billableHours.toFixed(2));
          const roundedNonBillable = parseFloat(worktime.nonBillableHours.toFixed(2));
          
          total.totalBillableHours += roundedBillable;
          total.totalNonBillableHours += roundedNonBillable;
        }
      });
    
      if (total.totalBillableHours === 0 && total.totalNonBillableHours === 0) {
        return null;
      }
  
      return {
        totalBillableHours: parseFloat(total.totalBillableHours.toFixed(2)),
        totalNonBillableHours: parseFloat(total.totalNonBillableHours.toFixed(2))
      };
    }
}