
<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Employee</h4>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">

        <div class="row mb-2">
            <div class="col-12">
                <label for="hourlyRate" class="form-label">{{'HourlyRateOfEmployee' | transloco}}</label>
                <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">CHF</span>
                    <input type="text" class="form-control" formControlName="hourlyRate">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer bg-dark text-light">
        <button type="button" class="btn btn-light" (click)="decline()">Cancel</button>
        <button type="submit" form="form" class="btn btn-success" (click)="onSubmit()"><i class="fas fa-save"></i> Save</button>
    </div>
</form>

