import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ProjectModel } from '../models/project/project.model';
import { ProjectUpdateModel } from '../models/project/project-update.model';

@Injectable({
    providedIn: 'root'
})

export class ProjectService {

    constructor(private http: HttpClient) { }

    // get a list of projects(active) between two dates 
    public getProjects(startDateAsString: string, endDateAsString: string): Observable<ProjectModel[]> {
        return this.http.get<ProjectModel[]>(`${environment.api}/project/activeByDateRange?startDateAsString=${startDateAsString}&endDateAsString=${endDateAsString}`);
    }

    // check if a list of projects(active) between two dates have at least a item incomplete
    public isAnyIncompleteProject(startDateAsString: string, endDateAsString: string): Observable<boolean> {
        return this.http.get<boolean>(`${environment.api}/project/haveIncompleteProjectsByDateRange?startDateAsString=${startDateAsString}&endDateAsString=${endDateAsString}`);
    }
    
    public getProjectById(id: string): Observable<ProjectModel> {
        return this.http.get<ProjectModel>(`${environment.api}/project/${id}`);
    }

    public updateProject(projectUpdate: ProjectUpdateModel): Observable<ProjectUpdateModel> {
        return this.http.put<ProjectUpdateModel>(`${environment.api}/project`, projectUpdate);
    }
}