import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { EmployeeModel } from 'src/app/project-insights/models/employee/employee.model';
import { EvaluationSearchDataModel } from 'src/app/project-insights/models/evaluation/evaluation-searchdata.model';
import { EmployeeService } from 'src/app/project-insights/services/employee.service';

@Component({
  selector: 'app-evaluation-searchbar',
  templateUrl: './evaluation-searchbar.component.html',
  styleUrls: ['./evaluation-searchbar.component.scss']
})

export class EvaluationSearchbarComponent implements OnInit {

    @Output() evaluationSearchData = new EventEmitter<EvaluationSearchDataModel>();

    daterange: FormControl = new FormControl();
    employees: EmployeeModel[] = [];
    
    dateRange: Date[] = [];
    selectedEmployeeId: FormControl = new FormControl('');

    private selectedEmployeeSub: any;
    private daterangeControlSub: any; 

    constructor(private employeeService: EmployeeService) {}

    ngOnInit(): void {
        this.setThisWeekDates();
        this.loadData();
        this.subscribeToControlChanges();
    }

    loadData(){
        this.employeeService.getEduEmployees().subscribe(res => {
            this.employees = res;
        });
    }

    resetSearchState() {
        this.unsubscribeFromControlChanges();

        this.selectedEmployeeId.reset();
        this.daterange.reset();
    
        this.subscribeToControlChanges();
      
        this.onSubmit();
    }

    onSubmit() {
        const issueSearchData = this.getFormData();
        this.evaluationSearchData.emit(issueSearchData);
    }

    subscribeToControlChanges(): void {
        this.selectedEmployeeSub = this.selectedEmployeeId.valueChanges
          .pipe(debounceTime(500), distinctUntilChanged())
          .subscribe(() => this.onSubmit());
    
        this.daterangeControlSub = this.daterange.valueChanges
          .pipe(distinctUntilChanged())
          .subscribe(() => this.onSubmit());
    }

    unsubscribeFromControlChanges(): void {
        if (this.daterange) {
          this.daterangeControlSub.unsubscribe();
        }

        if (this.selectedEmployeeId) {
            this.selectedEmployeeSub.unsubscribe();
        }
    }

    getFormData(): EvaluationSearchDataModel {
        const evaluationSearchData: EvaluationSearchDataModel = {
            startDate: this.daterange.value ? this.daterange.value[0] : null,
            endDate: this.daterange.value ? this.daterange.value[1] : null,
            employeeId: this.selectedEmployeeId.value
        };

        if(this.daterange.value){
            evaluationSearchData.startDate.setHours(0, 0, 0, 0);
            evaluationSearchData.endDate.setHours(23, 59, 59, 999);
        }

        return evaluationSearchData;
    }

    setLastMonthDates() {
        const dateNow = new Date();
        this.dateRange = [];
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, 1, 0, 0, 0, 0));
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth(), 0, 23, 59, 59, 999));
        this.daterange.patchValue(this.dateRange);

        this.onSubmit();
    }

    setCurrentMonthDates() {
        const dateNow = new Date();
        this.dateRange = [];
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1, 0, 0, 0, 0));
        this.dateRange.push(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0, 23, 59, 59, 999));
        this.daterange.patchValue(this.dateRange);
        
        this.onSubmit();
    }

    setThisWeekDates() {
        const dateNow = new Date();
        const currentDay = dateNow.getDay();
        
        let startDate = new Date(dateNow);
        startDate.setDate(dateNow.getDate() + 1 - currentDay);
        startDate.setHours(0, 0, 0, 0);

        let endDate = new Date(dateNow);
        endDate.setDate(dateNow.getDate() + 7 - currentDay);
        endDate.setHours(23, 59, 59, 999);

        this.dateRange = [];
        this.dateRange.push(startDate);
        this.dateRange.push(endDate);
        this.daterange.patchValue(this.dateRange);

        this.onSubmit();
    }
}
