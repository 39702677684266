import { Component, Input, OnInit } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { BillingGroupUpdateInvoiceInformationModalComponent } from './update-invoice-information-modal/update-invoice-information-modal.component';
import { BillingGroupModel } from 'src/app/project-insights/models/billing-group/billing-group.model';
import { CurrencyEnum } from 'src/app/shared/enums/currency.enum';
import { BillingGroupService } from 'src/app/project-insights/services/billing-group.service';
import { BankService } from 'src/app/project-insights/services/bank.service';
import { Bank } from 'src/app/project-insights/models/invoice/bank.model';
import { BillingGroupInvoiceInformationModel } from 'src/app/project-insights/models/billing-group/billing-group-invoice-information.model';
import { LanguageEnum } from 'src/app/shared/enums/language.enum';

@Component({
  selector: 'app-invoice-information',
  templateUrl: './invoice-information.component.html',
  styleUrls: ['./invoice-information.component.scss']
})
export class InvoiceInformationComponent implements OnInit {

  @Input() billingGroup: BillingGroupModel;
  CurrencyEnum = CurrencyEnum;
  banks: Bank[]
  languages: any;

  constructor(
    private modalService: BsModalService,
    private toastr: ToastrService,
    private billingGroupService: BillingGroupService,
    private bankService: BankService) { }

  ngOnInit(): void {
    this.getDropdownValues();
  }

  getDropdownValues() {
    this.bankService.getAllBanks().subscribe((banks: Bank[]) => {
      this.banks = banks;
    });

    this.languages = Object.values(LanguageEnum).filter(value => typeof value === 'number') as number[];
  }

  onEditInvoiceInformation() {
    const initialState: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        billingGroup: this.billingGroup,
        banks: this.banks,
        languages: this.languages
      },
    };
    const bsModalRef = this.modalService.show(BillingGroupUpdateInvoiceInformationModalComponent, initialState);

    bsModalRef.content?.confirmed.pipe(take(1))
      .subscribe((updatedInvoiceInformation: BillingGroupInvoiceInformationModel) => {
        this.billingGroupService.updateBillingGroupInvoiceInformation(this.billingGroup.id, updatedInvoiceInformation).subscribe(
          x => {
            this.billingGroup.customerOrderNumber = updatedInvoiceInformation.customerOrderNumber;
            this.billingGroup.bank = this.banks.find(x => x.id == updatedInvoiceInformation.bankId);
            this.billingGroup.language = updatedInvoiceInformation.language;
            this.billingGroup.connectedWithWorktimes = updatedInvoiceInformation.connectedWithWorktimes;
            this.billingGroup.internalBilling = updatedInvoiceInformation.internalBilling;
            this.billingGroup.bexioId = updatedInvoiceInformation.bexioId;
            this.toastr.success("Data successfully updated!")
          },
          err => {
            this.toastr.error(err.error, "An error occured!")
          })
      })
  }

}
