import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InvoiceStatusEnum } from 'src/app/shared/enums/invoice-status.enum';

@Component({
  selector: 'app-ag-grid-info-button',
  templateUrl: './ag-grid-info-button.component.html',
  styleUrls: []
})
export class AgGridInfoButtonComponent implements ICellRendererAngularComp {

  bsModalRef?: BsModalRef;
  params: ICellRendererParams;
  emailRecipients: string[];
  showIcon: boolean = true;
  
  constructor(private router: Router) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.showIcon = this.params.data.statusId !== InvoiceStatusEnum.SentToClient;
    if (this.params.data.billingGroup) {
        this.emailRecipients = this.params.data.billingGroup.emailRecipients;
    }
  }

  refresh() {
    return false;
  }
}
