import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class BexioInvoiceService {

    constructor(private http: HttpClient) { }

    sendInvoiceToBexio(id: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/bexioInvoice/${id}/send`);
    }
     
    updateInvoiceInBexio(id: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/bexioInvoice/${id}/update`);
    }

    importInvoicePdfById(id: string): Observable<string> {
        return this.http.get<string>(`${environment.api}/bexioInvoice/${id}/importBexioPdf`);
    }
}