import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BillingGroupInvoiceInformationModel } from 'src/app/project-insights/models/billing-group/billing-group-invoice-information.model';
import { BillingGroupModel } from 'src/app/project-insights/models/billing-group/billing-group.model';
import { Bank } from 'src/app/project-insights/models/invoice/bank.model';
import { CurrencyEnum } from 'src/app/shared/enums/currency.enum';

@Component({
  selector: 'app-billing-group-update-invoice-information-modal',
  templateUrl: './update-invoice-information-modal.component.html',
  styleUrls: ['./update-invoice-information-modal.component.scss']
})
export class BillingGroupUpdateInvoiceInformationModalComponent implements OnInit {

  @Output() confirmed = new EventEmitter<BillingGroupInvoiceInformationModel>();

  form: UntypedFormGroup;
  billingGroup: BillingGroupModel;
  CurrencyEnum = CurrencyEnum;
  banks: Bank[]
  languages: any;

  constructor(
    private fb: UntypedFormBuilder,
    private bsModalRef: BsModalRef,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup() {
    this.form = this.fb.group({
      customerOrderNumber: [this.billingGroup?.customerOrderNumber],
      bank: [this.billingGroup?.bank.id, Validators.required],
      folderName: [this.billingGroup?.folderName],
      language: [this.billingGroup?.language, Validators.required],
      connectedWithWorktimes: [this.billingGroup?.connectedWithWorktimes, Validators.required],
      internalBilling: [this.billingGroup?.internalBilling, Validators.required],
      bexioId: [this.billingGroup?.bexioId, Validators.required],
    })
  }

  onSubmit() {
    if (this.form.valid) {
      this.confirmed.emit(this.getInvoiceInformationDetails());
      this.bsModalRef.hide();
    }
    else {
      this.toastr.error("Es liegen noch Validierungsfehler vor!");
      this.form.markAllAsTouched();
    }
  }

  getInvoiceInformationDetails() {
    let updatedInvoiceInformationData: BillingGroupInvoiceInformationModel = {
      customerOrderNumber: this.form.controls['customerOrderNumber'].value,
      folderName: this.form.controls['folderName'].value,
      bankId: this.form.controls['bank'].value,
      language: this.form.controls['language'].value,
      connectedWithWorktimes: this.form.controls['connectedWithWorktimes'].value,
      internalBilling: this.form.controls['internalBilling'].value,
      bexioId: this.form.controls['bexioId'].value,
    };
    return updatedInvoiceInformationData;
  }

  public close() {
    this.bsModalRef.hide();
  }

  /* Add CSS if field is invalid */
  validateField(field: string) {
    if (this.form.get(field)!.invalid && this.form.get(field)!.touched) {
      return 'is-invalid'
    }
    return '';
  }

}
