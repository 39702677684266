import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AgGridBaseComponent } from 'src/app/shared/ag-grid-shared/ag-grid-base.component';
import { GridService } from 'src/app/shared/services/grid.service';
import { AgGridActionButtonsComponent } from './ag-grid-action-buttons/ag-grid-action-buttons.component';
import { BillingGroupGridColumnService } from './billing-group-grid-column.service';
import { UntypedFormControl } from '@angular/forms';
import { BillingGroupModel } from 'src/app/project-insights/models/billing-group/billing-group.model';
import { CurrencyEnum } from 'src/app/shared/enums/currency.enum';
import { BillingGroupService } from 'src/app/project-insights/services/billing-group.service';

@Component({
  templateUrl: './billing-group-overview.component.html',
  styleUrls: ['./billing-group-overview.component.scss'],
  providers: [GridService]
})
export class BillingGroupOverviewComponent extends AgGridBaseComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  public billingGroups: BillingGroupModel[] = [];
  public newBillingGroup: BillingGroupModel;
  public loading: boolean;
  public bsModalRef?: BsModalRef;

  activeSwitch = new UntypedFormControl()

  public CurrencyEnum: CurrencyEnum;
  public selectedCurrency: number;
  public isActiveClicked: boolean = false;
  public isInactiveClicked: boolean = false;

  public defaultColDef: ColDef = {
    filter: true,
    sortable: true,
    floatingFilter: true,
    resizable: true
  };

  constructor(
    private gridService: GridService,
    private modalService: BsModalService,
    private billingGroupService: BillingGroupService,
    private billingGroupGridColumnService: BillingGroupGridColumnService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadBillingGroups();
    this.initGrid();
    this.activeSwitch.valueChanges
      .subscribe((res) => {
        this.showActiveOrAll(res);
      })
  }

  private async initGrid() {
    this.addFrameWorkComponent('', AgGridActionButtonsComponent);
    const columnDefs = await this.billingGroupGridColumnService.getColumns();
    this.gridOptions.columnDefs = columnDefs;
    this.gridOptions.suppressRowTransform = true;
    this.gridConfigReady = true;
  }

  loadBillingGroups() {
    this.billingGroupService.getAllBillingGroups().subscribe((x) => {
      this.billingGroups = x;
      this.loading = false;
    });
  }

  public onGridReady(parameters: GridReadyEvent): void {
    super.onGridReady(parameters);
    this.showActiveOrAll(false);
    this.agGrid.api.sizeColumnsToFit();
    this.fillGrid();
  }

  showActiveOrAll(checked: boolean) {
    var isActiveFilterComponent = this.gridApi.getFilterInstance('isActive')!;
    if (checked) {
      isActiveFilterComponent.setModel({ type: 'equals', filter: '' });
    }
    else {
      isActiveFilterComponent.setModel({ type: 'equals', filter: 'true' });
    }
    this.gridApi.onFilterChanged();
  }

  private fillGrid() {
    if (!this.billingGroups || this.billingGroups == null) { return; }

    this.gridService.processGrid(this.agGrid, this.billingGroups, this.constructor.name);
  }
}
