<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Invoice Information</h4>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form" autocomplete="off">
        <div class="row mb-2">
            <div class="col-7">
                <label for="customerOrderNumberModal" class="form-label">{{'CustomerOrderNumber' | transloco}}</label>
                <input id="customerOrderNumberModal" type="text" class="form-control inlineInput"
                    [ngClass]="validateField('customerOrderNumber')" formControlName="customerOrderNumber">
            </div>
            <!-- Language as typeahead-dropdown -->
            <div class="col-5">
                <label for="languageModal" class="form-label">{{ 'Language' | transloco }}*</label>
                <ng-select id="languageModal" [virtualScroll]="true" placeholder="Select a Language.." formControlName="language"  [ngClass]="validateField('language')">
                    <ng-option *ngFor="let language of languages" [value]="language">{{'languageEnum.' + language | transloco}}</ng-option>
                </ng-select>
                <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
            </div>
        </div>
        <div class="row mb-2">
            <!-- Bank as typeahead-dropdown -->
            <div class="col-12">
                <label for="bankModal" class="form-label">{{ 'BankAccount' | transloco }}*</label>
                <ng-select id="bankModal" [virtualScroll]="true" placeholder="Select a Bank Account.." formControlName="bank"  [ngClass]="validateField('bank')">
                    <ng-option *ngFor="let bank of banks" [value]="bank.id">{{bank.name}} - {{CurrencyEnum[bank.currency] | uppercase}}</ng-option>
                </ng-select>
                <div class="invalid-feedback">{{ 'validationMsg.required' | transloco }}.</div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <label for="folderNamerModal" class="form-label">{{'FolderName' | transloco}}</label>
                <input id="folderNameModal" type="text" class="form-control inlineInput"
                    [ngClass]="validateField('customerOrderNumber')" formControlName="folderName"> 
                <small id="emailHelp" class="form-text text-muted">{{ 'ForDownload' | transloco }}</small>
            </div>
        </div>
        <div class="mb-2 mt-3">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="connectedWithWorktimes"
                formControlName="connectedWithWorktimes" [ngClass]="validateField('connectedWithWorktimes')">
                <label for="connectedWithWorktimes">Connect with worktimes</label>
            </div>
        </div> 
        <div class="mb-2 mt-3">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="internalBilling"
                formControlName="internalBilling" [ngClass]="validateField('internalBilling')">
                <label for="internalBilling">Internal billing</label>
            </div>
        </div> 
        <div class="mb-2 mt-3">
            <div class="col-12">
                <label for="bexioId" class="form-label">Bexio ID</label>
                <input id="bexioId" type="number" [min]="0" class="form-control inlineInput"
                    [ngClass]="validateField('bexioId')" formControlName="bexioId"> 
            </div>
        </div> 
    </form>
</div>
<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>