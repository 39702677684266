import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EmployeeModel } from '../models/employee/employee.model';
import { EmployeeUpdateModel } from '../models/employee/employee-update.model';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(private http: HttpClient) { }

    getAllEmployees(): Observable<EmployeeModel[]> {
        return this.http.get<EmployeeModel[]>(`${environment.api}/employee/all`);
    }

    getEduEmployees(): Observable<EmployeeModel[]> {
        return this.http.get<EmployeeModel[]>(`${environment.api}/employee/team/edu`);
    }

    public getEmployeeById(id: string): Observable<EmployeeModel> {
        return this.http.get<EmployeeModel>(`${environment.api}/employee/${id}`);
    }

    public updateEmployee(employeeUpdate: EmployeeUpdateModel): Observable<EmployeeUpdateModel> {
        return this.http.put<EmployeeUpdateModel>(`${environment.api}/employee`, employeeUpdate);
    }

}