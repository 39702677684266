import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { BillingGroupModel } from '../models/billing-group/billing-group.model';
import { ProjectModel } from '../models/project/project.model';
import { BillingGroupCreateModel } from '../models/billing-group/billing-group-create.model';
import { BillingGroupEmailSettingModel } from '../models/billing-group/billing-group-email-settings.model';
import { BillingGroupNameStatusModel } from '../models/billing-group/billing-group-name-status.model';
import { BillingGroupInvoiceInformationModel } from '../models/billing-group/billing-group-invoice-information.model';
import { BillingGroupAddressModel } from '../models/billing-group/billing-group-address.model';

@Injectable({
    providedIn: 'root'
})

export class BillingGroupService {

    constructor(private http: HttpClient) { }

    public getAllBillingGroups(): Observable<BillingGroupModel[]> {
        return this.http.get<BillingGroupModel[]>(`${environment.api}/billingGroup`);
    }

    public getAllActiveBillingGroups(): Observable<BillingGroupModel[]> {
        return this.http.get<BillingGroupModel[]>(`${environment.api}/billingGroup/active`);
    }

    public getActiveNotInternalBillingGroups(): Observable<BillingGroupModel[]> {
        return this.http.get<BillingGroupModel[]>(`${environment.api}/billingGroup/activeNotInternal`);
    }

    public getBillingGroupById(id: string): Observable<BillingGroupModel> {
        return this.http.get<BillingGroupModel>(`${environment.api}/billingGroup/${id}`);
    }

    public GetBillingGroupProjectsWorktimes(id: string, startDate: Date, endDate: Date): Observable<ProjectModel[]> {
        return this.http.get<ProjectModel[]>(`${environment.api}/billingGroup/${id}/projectworktimes?startDate=${startDate.toUTCString()}&endDate=${endDate.toUTCString()}`);
    }

    public updateBillingGroupAddress(id: string, billingGroupAddress: BillingGroupAddressModel): Observable<BillingGroupAddressModel> {
        return this.http.put<BillingGroupAddressModel>(`${environment.api}/billingGroup/${id}/address`, billingGroupAddress);
    }

    public updateBillingGroupInvoiceInformation(id: string, billingGroupInvoiceInformation: BillingGroupInvoiceInformationModel): Observable<BillingGroupInvoiceInformationModel> {
        return this.http.put<BillingGroupInvoiceInformationModel>(`${environment.api}/billingGroup/${id}/invoiceInformation`, billingGroupInvoiceInformation);
    }

    public updateBillingGroupNameAndStatus(id: string, billingGroupNameAndStatus: BillingGroupNameStatusModel): Observable<BillingGroupNameStatusModel> {
        return this.http.put<BillingGroupNameStatusModel>(`${environment.api}/billingGroup/${id}/nameAndStatus`, billingGroupNameAndStatus);
    }

    public updateBillingGroupEmailSettings(id: string, billingGroupEmailSettings: BillingGroupEmailSettingModel): Observable<BillingGroupEmailSettingModel> {
        return this.http.put<BillingGroupEmailSettingModel>(`${environment.api}/billingGroup/${id}/emailSettings`, billingGroupEmailSettings);
    }

    public createBillingGroup(createdBillingGroup: BillingGroupCreateModel): Observable<string> {
        return this.http.post<string>(`${environment.api}/billingGroup`, createdBillingGroup);
    }

    public deleteBillingGroup(id: string): Observable<BillingGroupModel> {
        return this.http.delete<BillingGroupModel>(`${environment.api}/billingGroup/${id}`);
    }
}